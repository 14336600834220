.cv-carousel{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}

.cv-title{
    color: #000;
    margin: 0;
    margin-top: 35px;
}

.cv-titles{
    color: #000;
    display: flex;
    margin: 20px 0 40px;
}


.cv-links{
    margin-right: 25px;
    margin-left: 25px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.cv-links::after{
    content: '';
    width: 0;
    height: 3px;
    background: #4285F4;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: 0.5s;
}

.cv-links.active::after{
    width: 100%;
}

.text-container {
    padding-top: 6rem;
}

.text-container h2 {
    color: #000;
}

.info-wrapper h2{
    color: #000;
}
.info-wrapper h3 {
    padding-top: 0.4rem ;
    color: #000;
}

.info-wrapper h4 {
    color: #000;
}

.info-wrapper p {
    padding: 0.4rem;
    color: #000;
}

@media screen and (max-width: 720px){
    .cv-links {
        margin-right: 10px; 
        margin-left: 10px; 
        font-size: 14px; 
    }
}
.footer {
    padding: 1.25rem;
    text-align: center;
    margin: 2rem 0 0;
}

.footer p {
color: #4285F4;
}

@media screen and (max-width: 720px){
    .footer{
        margin: 0;
        padding: 0.5rem;
    }
}
.children{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 11px;
    box-shadow: 0 3px 10px #4285F4;
    width: 44.75rem;
    padding: 1rem;

}

@media screen and (max-width: 720px){
    .children{
        width: 19.875rem;
    }
}
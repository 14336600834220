.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  max-width: 68.75rem;
  margin: auto;
  padding-top: 20px;
}

.hero img {
  height: 30rem;
  width: auto;
  border-radius: 10%;
}

.hero-text {
  scroll-margin-top: 220px;
  width: 25rem;
  padding: 0.625rem;
  border-radius: 6px;
  box-shadow: 0px 2px 15px 2px #4285F4;
}
  
.hero-text p {
  padding: 0.3rem 0;
  color: #000;
}

.hero-text h1 {
  font-size: 2.5rem;
  color: #000;
}

@media screen and (max-width: 720px){
  .hero {
    margin-top: -4rem;
    flex-direction: column;
  }

  .hero img {
      margin-top: 5rem;
      height: 25rem;
      width: auto;
    }

  .hero-text{
    width: 20.5rem;
  }
}

@media screen and (max-width: 420px) {
  .hero img {
    height: 20rem;
    width: auto;
  }

  .hero-text {
    width: 18.3rem;
  }
} 
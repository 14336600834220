.social-icons {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 1%;
    bottom: 50%;
}

.sosialIcon {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.5rem;
}

.sosialIcon:hover {
    transform: scale(1.2);
    transition: 0.3s;
}

@media screen and (max-width: 720px){
    .social-icons {
        display: flex;
        flex-direction: row;
        justify-content: center; 
        position: relative;
        right: 0;
        bottom: 0;
    }
    .sosialIcon{
        padding: 1rem 0.5rem;
        padding-bottom: 0rem;
    }
}
.nav {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 3.5rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.nav ul {   
  display: flex;
  gap: 1.9rem;
}

.nav ul li {
  display: inline-block;
  list-style: none;
}

.nav-links {
  color: #4285F4;
  text-decoration: none;
  margin-right: 10px;
}

.nav-link {
  color: #000;
  text-decoration: none;
  margin-right: 10px;
  font-weight: 100000;
  font-size: larger;
}

.nav-link:hover {
  color: #4285F4;
}


.small-menu{
  font-size: 2rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: none;
  color: #4285F4;
}

.small-menu.exiter {
  color: #fff;
}


.nav h1 {
  color: #4285F4;
}

@media screen and (max-width: 720px){
  .nav {
    padding: 0 1.5rem;
  }

  .nav ul {
    background-color: #4285F4;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    width: 30%;
    height: 100vh;
    padding-top: 80px;
    margin: auto;
    text-align: left;
    transform: translateX(120%);
    transition: transform 0.5s ease-in;
  }

  .nav ul li {
    display: block;
  }

  .nav-link {
    color: #fff;
  }

  .nav-link:hover {
    color: #000;
  }

  .nav ul .small-menu {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }

  .small-menu {
    display: block;
    font-size: 25px;
  }
  .nav ul.show {
    transform: translateX(0);
  }
}
.projects {
    padding-top: 6rem;
    margin-top: 2rem;
}

.project-image {
    width: 65%;
    height: 60%;
    border-radius: 11px;
}

.projects-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects-title {
    text-align: center;
    margin-bottom: 1rem;
    color: #000;
}

.project-container {   

    text-align: center;
    width: 21.875rem;
    height: 25rem;
    padding: 1rem;
    border-radius: 11px;
    box-shadow: 0 3px 10px #4285F4;
    padding: 20px;
    margin: 10px;
    cursor: pointer;
    text-decoration: none;
}

.arrow {
    display: inline;
    width: 20px; 
    height: 20px;
    vertical-align: middle;
}

.project-container:hover {
    background-color: #4285F4;
    transition: 0.3s;
}

.project-container:hover h3  {
    color: #fff;
    transition: 0.3s;
}

.project-container:hover p {
    color: #fff;
    transition: 0.3s;
}

.project-container:hover a {
    color: #fff;
    transition: 0.3s;
}

.project-container:hover .arrow{
    transition: 0.5s;
    transform: translateY(-3px) translateX(3px) scale(1.3); 
    filter: brightness(0) invert(1);
}



.project-container p {
    padding: 0.4rem;
    color:  #000;
}

.project-container h3 {
    color: #000;
}

.projects-container a {
    color: #4285F4;
    text-decoration: none;
}



@media screen and (max-width: 720px){
    .projects {
        padding-top: 8rem;
    }
    .projects-container {
        flex-direction: column;
    }

    .project-container {
        width: 20.875rem;
    }
}

@media screen and (max-width: 420px) {
    .project-container {
        width: 17.875rem;
    }
}
